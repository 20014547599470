.sub-menu {
    position: absolute;
    z-index: 9999;
    top: 100%;
    right: 0;
    @include min-screen(1200) {
        left: 0;
    }
    visibility: hidden;
    width: 250px;
    margin-top: 30px;
    margin-bottom: 0;
    padding: 18px 0;
    list-style: none;
    transition: all 0.4s ease 0.2s;
    opacity: 0;
    border-bottom: 4px solid $primary;
    background-color: #fff;
    box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
    background-color: #ffdd00;

    li {
        & + li {
            margin-left: 0px !important;
        }
    }
    .sub-menu-link {
        font-family: "Poppins Regular", sans-serif;
        // @include min-screen(1200){
        //   text-align: left;
        // }
        // text-align: right;
        text-align: left;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.375;
        display: block;
        padding: 8px 20px;
    }
    .sub-menu-item :hover {
        background-color: #39b54a;
        color: white;
    }
}

.main-menu {
    li {
        &:hover {
            .sub-menu {
                visibility: visible;
                margin-top: 15px;
                opacity: 1;
            }
        }
    }
}

/* Ensure sub-menu items are positioned relative for nested menus */
// .sub-menu li {
//     position: relative;
//     visibility: visible;
// }

/* Hidden nested menu */
.nested-menu {
    position: relative;
    visibility: visible;
    opacity: 1;
    // visibility: hidden;
    background-color: #39b54a;
    padding: 10px;
    top: 100%; /* Position nested menu directly below the sub-menu */
    left: 0; /* Align with the parent sub-menu */
    // opacity: 0;
    transition: opacity 0.2s ease, visibility 0.2s ease;
    z-index: 1000; /* Ensure it appears above other elements */
}

.sub-menu-btn {
    display: flex;
    justify-content: space-between;
    border: 0;
    outline: 0;
    width: 100%;
    background: none;
}
.sub-menu-btn:hover,
.sub-menu-btn:focus {
    border: none; /* Ensure no border on hover or focus */
    outline: none; /* Prevent focus outline */
}
