@font-face {
    font-family: "Poppins Regular";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Regular"),
        url("../../fonts/poppins/Poppins-Regular.ttf") format("ttf");
}

@font-face {
    font-family: "Poppins Extra Light";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Extra Light"),
        url("../../fonts/poppins/Poppins-ExtraLight.ttf") format("ttf");
}

@font-face {
    font-family: "Poppins Light";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Light"),
        url("../../fonts/poppins/Poppins-Light.ttf") format("ttf");
}

@font-face {
    font-family: "Poppins Thin";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Thin"),
        url("../../fonts/poppins/Poppins-Thin.ttf") format("ttf");
}

@font-face {
    font-family: "Poppins Extra Light Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Extra Light Italic"),
        url("../../fonts/poppins/Poppins-ExtraLightItalic.ttf") format("ttf");
}

@font-face {
    font-family: "Poppins Light Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Light Italic"),
        url("../../fonts/poppins/Poppins-LightItalic.ttf") format("ttf");
}

@font-face {
    font-family: "Poppins Thin Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Thin Italic"),
        url("../../fonts/poppins/Poppins-ThinItalic.ttf") format("ttf");
}

@font-face {
    font-family: "Poppins Medium";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Medium"),
        url("../../fonts/poppins/Poppins-Medium.ttf") format("ttf");
}

@font-face {
    font-family: "Poppins Semi Bold";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Semi Bold"),
        url("../../fonts/poppins/Poppins-SemiBold.ttf") format("ttf");
}

@font-face {
    font-family: "Poppins Bold";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Bold"),
        url("../../fonts/poppins/Poppins-Bold.ttf") format("ttf");
}

@font-face {
    font-family: "Poppins Bold Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Bold Italic"),
        url("../../fonts/poppins/Poppins-BoldItalic.ttf") format("ttf");
}

@font-face {
    font-family: "Poppins Medium Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Medium Italic"),
        url("../../fonts/poppins/Poppins-MediumItalic.ttf") format("ttf");
}

@font-face {
    font-family: "Poppins Semi Bold Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Semi Bold Italic"),
        url("../../fonts/poppins/Poppins-SemiBoldItalic.ttf") format("ttf");
}

@font-face {
    font-family: "Poppins Extra Bold";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Extra Bold"),
        url("../../fonts/poppins/Poppins-ExtraBold.ttf") format("ttf");
}

@font-face {
    font-family: "Poppins Extra Bold Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Extra Bold Italic"),
        url("../../fonts/poppins/Poppins-ExtraBoldItalic.ttf") format("ttf");
}

@font-face {
    font-family: "Poppins Black";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Black"),
        url("../../fonts/poppins/Poppins-Black.ttf") format("ttf");
}

@font-face {
    font-family: "Poppins Black Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Poppins Black Italic"),
        url("../../fonts/poppins/Poppins-BlackItalic.ttf") format("ttf");
}
