/* Mobile Browser */
@media (min-width: 576px) {
}

/* Laptop Browser */
@media (min-width: 992px) {
    .logo-width {
        width: 70%;
    }
    .item-desk {
        display: none !important;
    }
    .banner-width {
        width: 90%;
    }
    .awards-box {
        height: 140px;
        box-shadow: 0px 0px 5px 2px rgb(114 184 124);
        align-items: center;
        flex-direction: row;
        display: flex;
        border-radius: 0.75rem;
    }
    .awards-box:hover {
        box-shadow: 0px 0px 5px 2px rgb(75, 77, 75);
    }
    .bod-box {
        box-shadow: 0px 0px 2px 2px rgb(183 183 183);
        flex-direction: row;
        display: flex;
        border-radius: 0.75rem;
    }
}

/* Mobile Browser */
@media (max-width: 992px) {
    .item-mobile {
        display: none !important;
    }
    .logo-width {
        width: 100%;
    }
    .banner-width {
        width: 100%;
    }
    .awards-box {
        text-align: center;
        height: auto;
        box-shadow: 0px 0px 2px 2px rgb(171 244 181);
        border-radius: 0.75rem;
    }
}

table,
th,
td {
    border: 2px solid green;
}

th,
td {
    padding: 0.4em;
    text-align: left;
}

.g-color {
    color: #39b54a !important;
}

.y-color {
    color: #ffdd00 !important;
}

.b-color {
    color: #231f20 !important;
}

.g-background-color {
    background-color: #00ff2330;
}

.y-background-color {
    background-color: #ffff002e;
}

.b-background-color {
    background-color: #00000017;
}

.img-bottom-border {
    border-radius: 10px;
    border-bottom: #39b44a 4px solid;
}

.font-14 {
    font-size: 14px;
}

.padding-15 {
    /* padding: 15px !important; */
    height: 80px !important;
}

.pdf-div {
    background: #fff3a4 !important;
    height: 100%;
    border-radius: 0.75em !important;
}

.pdf-div:hover,
.pdf-div.active {
    background: #39b54a !important;
    color: white !important;
    -webkit-box-shadow: 0px 2px 27px 0px rgba(0, 0, 0, 0.09) !important;
    box-shadow: 0px 2px 27px 0px rgba(0, 0, 0, 0.09) !important;
}

.pdf-div-trans {
    background-color: transparent !important;
    border-radius: 0.75em !important;
    justify-content: flex-start;
}
.pdf-div-trans:hover,
.pdf-div-trans.active {
    background-color: transparent !important;
}

.pdf-div-highlight {
    background: #39b54a !important;
    height: 100%;
    border-radius: 0.75em !important;
}

.pdf-div-highlight:hover,
.pdf-div-highlight.active {
    background: #231f20 !important;
    -webkit-box-shadow: 0px 2px 27px 0px rgba(0, 0, 0, 0.09) !important;
    box-shadow: 0px 2px 27px 0px rgba(0, 0, 0, 0.09) !important;
}

.pdf-div-trans-highlight {
    background-color: transparent !important;
    border-radius: 0.75em !important;
    color: white !important;
}
.pdf-div-trans-highlight:hover,
.pdf-div-trans-highlight.active {
    background-color: transparent !important;
    color: white !important;
}

.grid-item-padding {
    padding: 0.8125rem 1.875rem !important;
    background-color: white !important;
    border-bottom: 1px solid;
    border-top: 0.5px green solid;
    border-left: 0.5px green solid;
    border-right: 0.5px green solid;
    height: 100px;
    align-content: center;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .grid-item-padding {
        padding: 25px !important;
        background-color: white !important;
    }
}

.home-Intro-image-2 {
    filter: drop-shadow(0px 2px 2px #ffdd00) sepia(1);
}

.bg-warning-light {
    background-color: #f4d4067a;
}

.box-shadow-gray {
    box-shadow: 0 0 40px 0 rgba(25, 25, 33, 0.08);
    background-color: white !important;
}

.info-box-shadow {
    box-shadow: -5px 6px 2px 5px rgb(167 240 177);
    padding: 0.8125rem 0.875rem !important;
}

.product-box-left-shadow {
    box-shadow: -5px 5px 5px -1px rgb(114 184 124);
    border-radius: 0.5rem;
}
.product-box-left-shadow:hover {
    box-shadow: -5px 5px 5px -1px rgb(75, 77, 75);
}

.product-box-right-shadow {
    box-shadow: 5px 5px 5px -1px rgb(114 184 124);
    border-radius: 0.5rem;
}
.product-box-right-shadow:hover {
    box-shadow: 5px 5px 5px -1px rgb(75, 77, 75);
}

.report-box {
    padding: 0.8125rem 0.875rem !important;
    border-radius: 0.5rem;
}

.report-box:hover {
    background-color: #39b5b0 !important;
}

.report-inner-box {
    padding: 0.8125rem 0.875rem !important;
    border-radius: 0.5rem;
    color: #39b54a;
    background-color: white;
    border: solid yellow 1px;
}

.report-inner-box:hover {
    color: #fff3a4;
    background-color: black;
    border: solid #39b54a 1px;
}

.impact-image {
    border-radius: 0.5rem;
    box-shadow: 0px 0px 5px 4px rgb(136 187 42);
}

.simpleborder {
    width: 6%;
    border-bottom: 2px solid #000;
    padding-top: 15px;
}

.list-items-dot > li::before {
    content: "•";
    color: #3a3a3a;
    font-size: 20px;
}

.list-items-dot > li::before {
    position: absolute;
    left: 0px;
}

.list-items-dot > li {
    position: relative;
    padding: 0px 0 0px 25px;
}

.swiper-slide {
    justify-items: center;
}

.swiper-button-prev,
.swiper-button-next {
    color: #39b54a;
    height: 25px;
}

.top-button {
    border: 1px solid #39b54a;
    color: #39b54a;
    background-color: #39b54a;
}
.top-button:hover {
    border-color: #000;
    background-color: black;
    color: #000;
}

.top-button-text {
    color: #000;
}

.top-button-text:hover {
    color: #39b54a;
}

.radius-box {
    border-radius: 0.75rem;
}

.mySwiper {
    margin-top: 10px;
}

.faq-accordion-box {
    box-shadow: 0px 0px 1px 1px rgb(199 199 199);
    padding: 5px;
    border-radius: 0.5rem;
    background-color: #f5f5f5;
}
