.service-shape {
    position: absolute;
    top: 0;
    @include min-screen(992) {
        top: 255px;
    }
    left: 0;
    z-index: -1;
}

.service-img {
    @include min-screen(1200) {
        margin-left: -90px;
    }
}

.service-thumb {
    padding: 35px 0px;
    margin-bottom: 40px;
    border-radius: 6px;
}

.single-service-list {
    margin-bottom: 60px;

    @include min-screen(992) {
        margin-bottom: 80px;
    }
    p {
        color: $dark;
        margin-bottom: 0.8rem;
        i {
            margin-right: 15px;
        }
    }
}

.sub-section-mb {
    margin-bottom: 60px;

    @include min-screen(992) {
        margin-bottom: 80px;
    }
}
.sub-section-mb70 {
    margin-bottom: 60px;

    @include min-screen(992) {
        margin-bottom: 70px;
    }
}

// sidbar-menu

.sidbar-menu {
    ul {
        margin-bottom: -15px;
        li {
            margin-bottom: 15px;
            button {
                border-bottom: 1px solid #e1e1e1;
                border-radius: 0 !important;
            }

            a,
            button {
                display: flex;
                align-items: center;
                font-family: $headings-font-family;
                text-transform: capitalize;
                font-weight: 600;
                color: $dark;
                line-height: 1.666;
                background: $gray-100;
                padding: 22px 25px;
                font-size: 18px;
                transition: all ease-in-out 0.5s;
                border-radius: 6px;
                width: 100%;
                &:hover,
                &.active {
                    background: $lime-600;
                    box-shadow: 0px 2px 27px 0px rgba(0, 0, 0, 0.09);
                }

                img {
                    margin-right: 15px;
                }
            }
        }
    }
}

.service-media-wrapper {
}

.media-spacing-right {
    @include min-screen(1200) {
        padding-left: 30px;
    }
}
.media-spacing-left {
    @include min-screen(1200) {
        padding-right: 30px;
    }
}

.service-media-img {
    @include min-screen(1200) {
        margin: 0 -20px;
    }
}

.service-media {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-bottom: 40px;
    border-bottom: 1px solid #e8e8e8;
    &:last-child {
        padding-bottom: 0px;
        margin-bottom: 0px;
        border-bottom: 0;
    }
    .logo {
        margin-right: 30px;
    }
    .title {
        color: $card-headings-color;
        text-transform: capitalize;
        font-weight: 600;
        line-height: 1.25;
        margin-bottom: 1.25rem;
        font-size: 24px;
        &:hover {
            color: $primary;
        }
        a {
            color: inherit;
        }
        @include max-screen(992, 1199) {
            font-size: 18px;
        }

        @include max-screen(767) {
            font-size: 20px;
        }
    }
    p {
    }
}

.service-media-body {
    flex: 1;
}
