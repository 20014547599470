.tam-grid {
  display: flex;
  flex-wrap: wrap;

  @include max-screen(768, 991) {
    margin-right: -15px;
    padding-left: 50px;
  }
  @include min-screen(1200) {
    padding-left: 1.875rem;
  }
  margin-right: -30px;
  @include max-screen(767) {
    margin-right: 0px;
  }
}
.team-grid-item {
  @include max-screen(575) {
    text-align: center;
  }
  width: 240px;
  @include max-screen(992, 1199) {
    width: 230px;
  }

  @include max-screen(576, 767) {
    width: 40%;
  }

  @include max-screen(575) {
    width: 100%;
  }

  margin-right: 30px;
  margin-right: 15px;

  @include max-screen(575) {
    margin-right: 0px;
  }
  &:nth-child(1) {
    margin-left: 40px;
    margin-right: 45px;

    @include max-screen(992, 1199) {
      margin-left: 20px;
      margin-right: 25px;
    }

    @include max-screen(576, 767) {
      margin-left: 20px;
      margin-right: 30px;
    }
    @include max-screen(575) {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  &:nth-child(2) {
    margin-top: 5.625rem;

    @include max-screen(575) {
      margin-top: 0rem;
    }
  }

  @include max-screen(575) {
    text-align: center;
  }

  &:last-child {
    margin-left: -40px;

    @include max-screen(992, 1199) {
      margin-left: -20px;
    }

    @include max-screen(576, 767) {
      margin-left: -20px;
    }

    @include max-screen(575) {
      margin-left: 0px;
    }
  }
}

.team-card {
  text-align: center;
  padding: 35px;
  border: 1px solid #39B54A;
  background-color: #fbfbfb;
  border-radius: 15px;
  transition: all 0.3s;
  &:hover,
  &.active {
    border-color: #263238;
    box-shadow: 0px 9px 35px 0px rgba(0, 0, 0, 0.09);
  }
  .thumb {
    a {
      display: block;
      // img {
      //   width: 100%;
      // }
    }
  }
  .content {
    padding: 1.25rem 0rem;
    .title {
      transition: all 0.3s ease-in-out;
      color: $card-headings-color;
      &:hover {
        color: $primary;
      }
      line-height: 1.666;
      font-weight: 600;
      margin-bottom: 0;
      font-size: 18px;
    }
  }
}

.new-team-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: -2.1875rem;
  @include max-screen(991) {
    margin-top: 50px;
  }
  @include max-screen(481, 767) {
    margin-left: -15px;
    margin-right: -15px;
    }
}
.new-team-members-list {
  text-align: center;
  padding: 35px;
  border: 1px solid #39B54A;
  background-color: #fbfbfb;
  &:hover {
    border-color: #263238;
    box-shadow: 0px 9px 35px 0px rgba(0, 0, 0, 0.09);
  }
  border-radius: 85px;
  margin-bottom: 2.1875rem;
  transition: all 0.3s;
  @include max-screen(576, 1199) {
    min-width: 240px;
  }
  @include max-screen(481, 767) {
    width: calc(50% - 30px);
  }
  img {
    margin-bottom: 25px;
  }
  .title {
    transition: all 0.3s ease-in-out;
    color: $card-headings-color;
    &:hover {
      color: $primary;
    }
    line-height: 1.666;
    font-weight: 600;
    margin-bottom: 0;
    font-size: 18px;
  }
  @include max-screen(481, 767) {
    margin-left: 15px;
    margin-right: 15px;
    }

  &:nth-child(2) {
    @include min-screen(768) {
      margin-left: -30px;
    }
  }
  &:nth-child(3) {
    @include min-screen(768) {
      margin-left: -30px;
    }
  }
}
